.slider-simple-wrapper {
	position: relative;
	margin-bottom: @paragraph-margin-bottom * 2;
}
.slider-simple {
	width: auto;
	height: auto;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	padding-left: 55px;
	padding-right: 55px;
}
.slider-simple-item {
	& img {
		width: auto;
		height: auto;
		max-width: 100%;
		max-height: 100%;
	}
}
.slider-simple__button-prev,
.slider-simple__button-next {
	position: absolute;
	z-index: 5;
	top: 50%;
	border: 0;
	background-color: transparent;
	padding: 0;
	transform: translateY(-50%);

	&:focus { outline: 0; }

	&:hover {
		& .icon {
			fill: @primary-color;
		}
	}

	& .icon {
		fill: #0c4733;
		transition: 0.2s fill ease;
	}
}
.slider-simple__button-prev {
	left: 0;
	& .icon {
		transform: rotate(90deg);
	}
}
.slider-simple__button-next {
	right: 0;
	& .icon {
		transform: rotate(-90deg);
	}
}

.slick-arrow {
	border: 1px solid @link-color;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	padding-top: 0px;
	 .icon {
	 	width: 15px;
	 	height: 10px;
	 	fill: @link-color;
	 }
	 &:hover {
	 	border-color: @link-hover;
	 	.icon {
		 	fill: @link-hover;
		 }
	 }
}
.slider-simple__button-prev {
	padding-right: 2px;
}
.slider-simple__button-next {
	padding-left: 2px;
}

@media (max-width: @screen-md) {
	.slider-simple {
		width: auto;
		height: auto;
	}
}
@media (max-width: @screen-sm) {
	.slider-simple {
		width: auto;
		height: auto;
		max-height: 458px;
	}
}
