.breadcrumb {
	list-style-type: none;
	padding-left: 28px;
	padding-right: 0;
	padding-top: @breadcrumb-padding-top;
	padding-bottom: @breadcrumb-padding-bottom;
	margin: 0;
	margin-bottom: 7px;
	background: #fff;

	& a {
		font-size: @breadcrumb-font-size;
		line-height: @breadcrumb-line-height;
		font-weight: @breadcrumb-font-weight;
		text-transform: @breadcrumb-text-transform;
		color: @breadcrumb-color-normal;
		text-decoration: none;
		padding-right: 5px;

		&[href] {
			& span {
				text-decoration: underline;
			}
		}

		& span {
			overflow: hidden;
		}

		&[href]:hover {
			color: @breadcrumb-color-hover;

			& span {
				text-decoration: none;
			}

			& svg {
				fill: @breadcrumb-color-hover;
			}
		}
		&::after {
			display: inline-block;
			content: @breadcrumb-separator;
			padding-left: 11px;
    		padding-right: 5px;
			text-decoration: none;
		}
	}
	& li {
		float: left;

		&:last-child a::after {
			display: none;
		}
		&.active a {
			color: @breadcrumb-color-hover;
			& span {
				text-decoration: none;
			}
		}
		& svg {
			display: @breadcrumb-icon-display;
			fill: @breadcrumb-color-normal;
			width: 11px;
			height: 11px;
			margin-right: 5px;
		}
	}
}
