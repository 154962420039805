.pagination-wrapper {
	text-align: @pagination-align;
}

.pagination,
.content .pagination {
	margin-bottom: @pagination-margin-bottom;
	display: -webkit-inline-flex;
	display: -moz-inline-flex;
	display: -ms-inline-flex;
	display: -o-inline-flex;
	display: inline-flex;
	align-items: center;
	margin-left: 0;
	padding-left: 0;

	& li {
		width: @pagination-control-size;
		height: @pagination-control-size;
		margin-right: 7px;
		margin-bottom: 0;
		padding-left: 0;

		&::before { display: none !important; }

		&:last-child {
			margin-right: 0;
		}
	}

	& a,
	& span {
		display: block;
		width: 100%;
		height: 100%;
		color: @pagination-text-color;
		font-size: @pagination-font-size;
		font-weight: @pagination-font-weight;
		text-decoration: none;
		text-align: center;
		line-height: @pagination-control-size;
		border: 1px solid @gray;
		background: transparent;
		transition: 0.15s background-color ease-in;

		&:visited {
			color: @pagination-text-color;
		}

		&:hover {
			background-color: #ffffff;
			border-color: @pagination-main-color;
			color: #000;
		}

		&.active {
			background-color: @pagination-main-color;
			color: #fff;
			cursor: default;
			border-color: @pagination-main-color;
		}
		&.disabled {
			color: #777;
			cursor: not-allowed;
			background-color: #fff;
			border-color: #ddd;
			& .icon {
				fill: #777;
			}
		}
	}
}


.pagination .pagination__prev,
.content .pagination .pagination__prev,
.pagination .pagination__next,
.content .pagination .pagination__next {
	border: 0;
	text-decoration: underline;

	.icon {
		width: auto;
		height: @pagination-control-size;
		fill: @pagination-main-color;
		transition: 0.15s fill ease-in;
		border: 0px;
	}

	&:hover {
		background-color: transparent;
		color: inherit;
	}
}

.pagination__prev {
	& .icon {
		transform: rotate(-180deg);
	}
}

@media (max-width:@screen-md) {
	.content .pagination,
	.pagination {
		flex-wrap: wrap;
		margin-bottom: 20px;

		& li {
			margin-bottom: 20px;
		}
	}
}
