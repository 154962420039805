/*medium - 500*/
/*roman - 400*/
/*light - 300*/
/*thin - 200*/
/*ultralight - 100*/

/*
@font-face {
	font-family: "helveticaneuecyr";
	src: url("../fonts/helveticaneuecyr/medium/helveticaneuecyr-medium.woff2?v=1.1.0") format("woff2"),
	url("../fonts/helveticaneuecyr/medium/helveticaneuecyr-medium.woff?v=1.1.0") format("woff");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "helveticaneuecyr";
	src: url("../fonts/helveticaneuecyr/roman/helveticaneuecyr-roman.woff2?v=1.1.0") format("woff2"),
	url("../fonts/helveticaneuecyr/roman/helveticaneuecyr-roman.woff?v=1.1.0") format("woff");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "helveticaneuecyr";
	src: url("../fonts/helveticaneuecyr/light/helveticaneuecyr-light.woff2?v=1.1.0") format("woff2"),
	url("../fonts/helveticaneuecyr/light/helveticaneuecyr-light.woff?v=1.1.0") format("woff");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "helveticaneuecyr";
	src: url("../fonts/helveticaneuecyr/thin/helveticaneuecyr-thin.woff2?v=1.1.0") format("woff2"),
	url("../fonts/helveticaneuecyr/thin/helveticaneuecyr-thin.woff?v=1.1.0") format("woff");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: "helveticaneuecyr";
	src: url("../fonts/helveticaneuecyr/ultralight/helveticaneuecyr-ultralight.woff2?v=1.1.0") format("woff2"),
	url("../fonts/helveticaneuecyr/ultralight/helveticaneuecyr-ultralight.woff?v=1.1.0") format("woff");
	font-weight: 100;
	font-style: normal;
}


@font-face {
	font-family: "bitter";
	src: url("../fonts/bitter/regular/bitter-regular.woff2?v=1.1.0") format("woff2"),
	url("../fonts/bitter/regular/bitter-regular.woff?v=1.1.0") format("woff");
	font-weight: 400;
	font-style: normal;
}
*/
@font-face {
	font-family: "Glyphicons Halflings";
	src: url("../fonts/glyphicons/regular/glyphicons-halflings-regular.woff2?v=1.1.0") format("woff2"),
	url("../fonts/glyphicons/regular/glyphicons-halflings-regular.woff?v=1.1.0") format("woff");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "museocyrl";
	src: url("../fonts/museocyrl/museocyrl.woff2?v=1.1.0") format("woff2"),
	url("../fonts/museocyrl/museocyrl.woff?v=1.1.0") format("woff");
	font-weight: 500;
	font-style: normal;
}
/*
@font-face {
  font-family: "akrobat";
  src: url("../fonts/akrobatsemibold/akrobatsemibold.woff2") format("woff2"),
  url("../fonts/akrobatsemibold/akrobatsemibold.woff") format("woff"),
  url('../fonts/akrobatsemibold/akrobatsemibold.ttf') format('truetype'),
  url('../fonts/akrobatsemibold/akrobatsemibold.svg#DINPro') format('svg'),
  url('../fonts/akrobatsemibold/akrobatsemibold?#iefix') format('embedded-opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "akrobat";
  src: url("../fonts/akrobatregular/akrobatregular.woff2") format("woff2"),
  url("../fonts/akrobatregular/akrobatregular.woff") format("woff"),
  url('../fonts/akrobatregular/akrobatregular.ttf') format('truetype'),
  url('../fonts/akrobatregular/akrobatregular.svg#DINPro') format('svg'),
  url('../fonts/akrobatregular/akrobatregular?#iefix') format('embedded-opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "akrobat";
  src: url("../fonts/akrobatlight/akrobatlight.woff2") format("woff2"),
  url("../fonts/akrobatlight/akrobatlight.woff") format("woff"),
  url('../fonts/akrobatlight/akrobatlight.ttf') format('truetype'),
  url('../fonts/akrobatlight/akrobatlight.svg#DINPro') format('svg'),
  url('../fonts/akrobatlight/akrobatlight?#iefix') format('embedded-opentype');
  font-weight: 200;
  font-style: normal;
}
*/












@font-face {
  font-family: "robotocondensed";
  src: url("../fonts/robotocondensedlight/robotocondensedlight.woff2") format("woff2"),
  url("../fonts/robotocondensedlight/robotocondensedlight.woff") format("woff"),
  url('../fonts/robotocondensedlight/robotocondensedlight.ttf') format('truetype'),
  url('../fonts/robotocondensedlight/robotocondensedlight.svg#DINPro') format('svg'),
  url('../fonts/robotocondensedlight/robotocondensedlight?#iefix') format('embedded-opentype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "robotocondensed";
  src: url("../fonts/robotocondensedregular/robotocondensedregular.woff2") format("woff2"),
  url("../fonts/robotocondensedregular/robotocondensedregular.woff") format("woff"),
  url('../fonts/robotocondensedregular/robotocondensedregular.ttf') format('truetype'),
  url('../fonts/robotocondensedregular/robotocondensedregular.svg#DINPro') format('svg'),
  url('../fonts/robotocondensedregular/robotocondensedregular?#iefix') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "robotocondensed";
  src: url("../fonts/robotocondensedbold/robotocondensedbold.woff2") format("woff2"),
  url("../fonts/robotocondensedbold/robotocondensedbold.woff") format("woff"),
  url('../fonts/robotocondensedbold/robotocondensedregular.ttf') format('truetype'),
  url('../fonts/robotocondensedbold/robotocondensedbold.svg#DINPro') format('svg'),
  url('../fonts/robotocondensedbold/robotocondensedbold?#iefix') format('embedded-opentype');
  font-weight: 600;
  font-style: normal;
}
/* font-family: "RobotoCondensedLight";
@font-face {
  font-family: "robotocondensed";
  src: url("../fonts/robotocondensedlight/robotocondensedlight.eot");
  src: url("../fonts/robotocondensedlight/robotocondensedlight.eot?#iefix") format("embedded-opentype"), url("../fonts/robotocondensedlight/robotocondensedlight.woff") format("woff"), url("../fonts/robotocondensedlight/robotocondensedlight.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
} 
@font-face {
  font-family: "robotocondensed";
  src: url("../fonts/robotocondensedregular/robotocondensedregular.eot");
  src: url("../fonts/robotocondensedregular/robotocondensedregular.eot?#iefix") format("embedded-opentype"), url("../fonts/robotocondensedregular/robotocondensedregular.woff") format("woff"), url("../fonts/robotocondensedregular/robotocondensedregular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
*/
/* font-family: "RobotoCondensedBold"; 
@font-face {
  font-family: "robotocondensed";
  src: url("../fonts/robotocondensedbold/robotocondensedbold.eot");
  src: url("../fonts/robotocondensedbold/robotocondensedbold.eot?#iefix") format("embedded-opentype"),
  url("../fonts/robotocondensedbold/robotocondensedbold.woff") format("woff"), 
  url("../fonts/robotocondensedbold/robotocondensedbold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}
*/



/**
* rouble font
*/
// @font-face {
//     font-family: 'rouble';
//     src: url('../fonts/rouble-webfont.woff') format('woff'),
//          url('../fonts/rouble-webfont.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }
