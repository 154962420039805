// Collapsible panels (aka, accordion)
//
// Wrap a series of panels in `.panel-group` to turn them into an accordion with
// the help of our collapse JavaScript plugin.

.panel-group {
	margin-bottom: @paragraph-margin-bottom * 2;

	& .panel {
		margin-bottom: 20px;
		border-radius: 4px;
	}

	.panel-title {
		margin-bottom: 0;

		& a {
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			height: 100%;
			padding: 10px 15px;
			text-decoration: none;
			background-color: #fff;
			transition: 0.15s background-color ease-in;
			border-radius: 4px;


			& .panel-title-link__text {
				color: @link-color;
				font-family: @title-font;
			}

			&:hover .panel-title-link__text {
				color: @primary-color;
			}

			&:not(.collapsed) {
				& .panel-title-link__text {
					color: @primary-color;
					font-weight: 500;
					background-color: transparent;
				}

				& .panel-title-link__icon {
					& .icon {
						width: 12px;
						height: 12px;
						fill: @primary-color-2;
						transform: rotate(0);
					}
				}
			}

		}
	}

	& .panel-heading {
		border-bottom: 0;
		border-radius: 4px;
	}

	.panel-footer {
		border-top: 0;
		+ .panel-collapse .panel-body {
			border-bottom: 1px solid @gray--darken;
		}
	}
}

.panel-body {
	padding: 10px 15px 25px;
}
.panel-default {
	border: 1px solid #e2e2e2;

	&.active {
		border-color: @primary-color-2;
		border-radius: 4px;
		//outline: 1px solid @primary-color;
	}
}
.collapse.in {
	display: block;
}
.collapse {
	display: none;
}
.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	transition-timing-function: ease;
	transition-duration: .35s;
	transition-property: height,visibility;
}
.panel-title-link__icon {
	height: 30px;
	width: 30px;
	display: block;
	border: 2px solid @primary-color-2;
		border-radius: 4px;
		padding-left: 7px; 
		padding-top: 1px;
	& .icon {
		width: 12px;
		height: 12px;
		fill: #6e6b6b;
		transform: rotate(-45deg);
		transition: 0.15s transform ease-in;
		
	}
}
.panel-title-link__text {
	transition: 0.15s color ease-in;
}

.collapsed {
	.panel-title-link__icon {
		border: 2px solid #6e6b6b;
	}
}