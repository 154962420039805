
.spoiler {
	margin-top: @spoiler-margin-top;
	margin-bottom: @spoiler-margin-bottom;
}

.spoiler__text {
	display: none;
	padding-top: 20px;
}

.spoiler__title {
	position: relative;
	display: inline-block;
	vertical-align: top;
	margin-right: 25px;
	color: @spoiler-title-normal-color;
	font-weight: @spoiler-title-font-weight;
	font-size: @spoiler-title-font-size;
	border-bottom-width: @spoiler-title-border-width;
	border-bottom-style: @spoiler-title-border-type;
	border-bottom-color: @spoiler-title-border-color;

	cursor: pointer;
}

.spoiler-title-icon-wrapper {
	position: absolute;
	top: 3px;
	right: -25px;
	width: 14px;
	height: 14px;
	fill: @link-color;

	& .icon {
		width: 14px;
		height: 14px;

		transition: 0.15s transform ease;
	}
}

.spoiler__title:hover  {
	color: @spoiler-title-hover-color;
	border-bottom-color: @spoiler-title-hover-color;

& .icon {
		fill: @spoiler-title-hover-color;
	}
}


.spoiler.active {

	& .spoiler__text {
		display: block;
	}

	& .spoiler__title {
		color: @spoiler-title-active-color;
		border-bottom-color: @spoiler-title-active-color;
	}

	& .icon {
		fill: @spoiler-title-active-color;
		transform: rotate(180deg);
	}

}
