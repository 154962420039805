/**
* wm buttons
*/
// класс для сброса стилей браузера и создания общих стилей для кнопок
.btn,
.content .btn, {
	display: inline-block;

	padding-top: @button-padding-top;
	padding-bottom: @button-padding-bottom;
	padding-left: @button-padding-left;
	padding-right: @button-padding-right;

	border-width: @button-border-width;
	border-style: @button-border-style;
	border-radius: @button-border-radius;

	font-size: @button-font-size;
	font-weight: @button-font-weight;
	line-height: 1.1;
	text-decoration: none;

	cursor: pointer;

	transition-duration: @button-transition-duration;
	transition-timing-function: ease-out;
	transition-property: border-color, background-color;
}

.btn--sm,
.content .btn--sm, {
	padding: @button-sm-padding;
	border-radius: @button-sm-border-radius;
	font-size: @button-sm-font-size;
}

/**
* button primary
*/
.btn--primary,
.content .btn--primary {
	border-color: @button-primary-border;
	background-color: @button-primary-bg;
	color: @button-primary-color;

	&:hover {
		border-color: @button-primary-border-hover;
		background-color: @button-primary-bg-hover;
		color: @button-primary-color-hover;
	}
	&:visited {
		color: @button-primary-color;
	}
}


/**
* button secondary
*/
.btn--secondary,
.content .btn--secondary {
	border-color: @button-secondary-border;
	background-color: @button-secondary-bg;
	color: @button-secondary-color;

	&:hover {
		border-color: @button-secondary-border-hover;
		background-color: @button-secondary-bg-hover;
		color: @button-secondary-color-hover;
	}
	&:visited {
		color: @button-secondary-color;
	}
}


/**
* button transparent
*/
.btn--transparent,
.content .btn--transparent {
	border-color: @button-transparent-border;
	background-color: transparent;
	color: @button-transparent-color;

	&:hover {
		border-color: @button-transparent-border-hover;
		background-color: @button-transparent-bg-hover;
		color: @button-transparent-color-hover;
	}
	&:visited {
		color: @button-transparent-color;
	}


	&.btn--primary {
		border-color: @button-primary-border;
		color: @button-primary-border;
	}

	&.btn--secondary {
		border-color: @button-secondary-border;
		color: @button-secondary-border;
	}
}


/**
* button disabled
*/
.btn.btn--disabled,
.btn[disabled],
.content .btn[disabled],
.content .btn.btn--disabled {
	pointer-events: none; // ссылка не работает
	cursor: not-allowed;
	box-shadow: none;
	background-color: @button-disabled-bg-color;
	border-color: @button-disabled-border-color;
	color: @button-disabled-color;

	&:hover {
		border-color: @button-disabled-bg-color;
		background-color: @button-disabled-border-color;
		color: @button-disabled-color;
	}
	&:visited {
		color: @button-disabled-color;
	}
}


/**
* основная кнопка
*/
.btn--link,
.content .btn--link {
	border-color: @button-link-border;
	background-color: @button-link-bg;
	color: @button-link-color;
	text-decoration: @button-link-text-decoration;

	&:hover {
		border-color: @button-link-border-hover;
		background-color: @button-link-bg-hover;
		color: @button-link-color-hover;
	}
	&:focus {
		border-color: @button-link-border-hover;
		background-color: @button-link-bg-hover;
		color: @button-link-color-hover;
	}
	&:visited {
		color: @button-link-color;
	}
}

@media (max-width: 1024px) {
	.btn {
		padding-left: 20px;
		padding-right: 20px;
	}
}
@media (max-width: @screen-sm) { /* 768px по умолчанию */
  .btn,
  .content .btn {
    padding-left: 20px;
    padding-right: 20px;
  }
}
