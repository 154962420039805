/**
* form style
*/
.site-form-wrapper {
	border: 1px solid @gray;
	margin-bottom: 40px;
	background: #f4f4f4;
	display: inline-block;
	min-width: 478px;
}
.site-form {
	padding-top: 26px;
	padding-left: 72px;
	padding-right: 72px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 10px;
}
.site-form__head.h2 {
	font-size: 20px !important;
	color: #424554 !important;
	text-align: center;
	margin-bottom: 8px;	
}
.site-form-element-wrapper {
	position: relative;
	margin-bottom: 19px;
}

.site-form__label {
	display: block;
	width: 100%;
	font-size: @form-label-font-size;
	font-weight: @form-label-font-weight;
	color: @form-label-text-color;
}

.site-form__input {
	display: block;
	width: 100%;
	height: @form-input-height;
	border: 1px solid @gray--darken;
	border-radius: @form-elements-border-radius;
	background-color: #fff;
	padding-left: 15px;
	padding-right: 15px;

	transition-duration: 0.15s;
	transition-property: background-color;
	transition-timing-function: ease-in;

	&:focus {
		outline: 0;
		border-color: #207ace;
		background-color: #fff;
	}
	&:hover {
		background-color: #fff;
	}
}

.site-form__textarea {
	display: block;
	width: 100%;
	min-height: @form-textarea-min-height;
	background-color: #fff;
	border: 1px solid @gray--darken;
	border-radius: @form-elements-border-radius;
	padding: 15px;
	resize: @form-textarea-resize;

	transition-duration: 0.15s;
	transition-property: background-color;
	transition-timing-function: ease-in;

	&:focus {
		border-color: #207ace;
		outline: 0;
		background-color: #fff;
	}

	&:hover {
		background-color: #fff;
	}
}

.site-form__input.error {
	border-color: @form-error-border-color;
	background-color: @form-error-background-color;
	&:focus {
		outline: 0;
	}
}

.site-form label.error {
	left: @grid-gutter-width / 2;
	color: @form-error-border-color;
	font-size: 12px;
}

.content .site-form__button-submit,
.site-form__button-submit {
	padding-left: 25px;
	padding-right: 25px;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

@media (max-width: @screen-sm) { /* 768px по умолчанию */
  .content .site-form__button-submit,
  .site-form__button-submit {
    padding-left: 20px;
    padding-right: 20px;
  }
}



.checkbox {
  vertical-align: top;
  margin: 0 3px 0 0;
  width: 17px;
  height: 17px;
}
.checkbox + label {
  cursor: pointer;
}
/* Прячем оригинальный чекбокс. */
.checkbox:not(checked) {
  position: absolute;
  opacity: 0;
}
.checkbox:not(checked) + label {
  position: relative; /* будем позиционировать псевдочекбокс относительно label */
  padding: 0 0 0 30px; /* оставляем слева от label место под псевдочекбокс */
}
/* Оформление первой части чекбокса в выключенном состоянии (фон). */
.checkbox:not(checked) + label:before {
  content: '';
  position: absolute;
  top: -4px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 1px;
  background: #fff;
  border: 1px solid @gray;
}
/* Оформление второй части чекбокса в выключенном состоянии (переключатель). */
.checkbox:not(checked) + label:after {
  content: '';
  position: absolute;
  top: 0px;
  left: 4px;
  width: 12px;
  height: 12px;  
  background: #fff;  
  transition: all .2s; /* анимация, чтобы чекбокс переключался плавно */
}
/* Меняем фон чекбокса, когда он включен. */
.checkbox:checked + label:before {
  //border-color: #000;
}
/* Сдвигаем переключатель чекбокса, когда он включен. */
.checkbox:checked + label:after {
  background: #bfbdbb;
}
/* Показываем получение фокуса. */
.checkbox:focus + label:before {
  //box-shadow: 0 0 0 3px rgba(255,255,0,.5);
}

.site-form__checkbox-label {
	width: 90%;
}

