.tooltip {
	position: absolute;
	z-index: 1070;
	display: block;
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.42857143;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	word-wrap: normal;
	white-space: normal;
	opacity: 0;
	line-break: auto;

	&.top {
		padding: 5px 0;
		margin-top: -3px;

		& .tooltip-arrow {
			bottom: 0;
			left: 50%;
			margin-left: -5px;
			border-width: 5px 5px 0;
			border-top-color: @gray--darken;
		}
	}
	&.left {
		padding: 0 5px;
		margin-left: -3px;

		& .tooltip-arrow {
			top: 50%;
			right: 0;
			margin-top: -5px;
			border-width: 5px 0 5px 5px;
			border-left-color: @gray--darken;
		}
	}
	&.bottom {
		padding: 5px 0;
		margin-top: 3px;

		& .tooltip-arrow {
			top: 0;
			left: 50%;
			margin-left: -5px;
			border-width: 0 5px 5px;
			border-bottom-color: @gray--darken;
		}
	}
	&.right {
		padding: 0 5px;
		margin-left: 3px;

		& .tooltip-arrow {
			top: 50%;
			left: 0;
			margin-top: -5px;
			border-width: 5px 5px 5px 0;
			border-right-color: @gray--darken;
		}
	}
}



// .tooltip.top {
// 	padding: 5px 0;
// 	margin-top: -3px;
// }
// .tooltip.left {
// 	padding: 0 5px;
// 	margin-left: -3px;
// }
// .tooltip.bottom {
// 	padding: 5px 0;
// 	margin-top: 3px;
// }
// .tooltip.right {
// 	padding: 0 5px;
// 	margin-left: 3px;
// }


.tooltip-arrow {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
}
// .tooltip.top .tooltip-arrow {
// 	bottom: 0;
// 	left: 50%;
// 	margin-left: -5px;
// 	border-width: 5px 5px 0;
// 	border-top-color: #000;
// }
// .tooltip.left .tooltip-arrow {
// 	top: 50%;
// 	right: 0;
// 	margin-top: -5px;
// 	border-width: 5px 0 5px 5px;
// 	border-left-color: #000;
// }
// .tooltip.bottom .tooltip-arrow {
// 	top: 0;
// 	left: 50%;
// 	margin-left: -5px;
// 	border-width: 0 5px 5px;
// 	border-bottom-color: #000;
// }
// .tooltip.right .tooltip-arrow {
// 	top: 50%;
// 	left: 0;
// 	margin-top: -5px;
// 	border-width: 5px 5px 5px 0;
// 	border-right-color: #000;
// }







.tooltip-inner {
	max-width: 200px;
	padding: 6px 12px;
	color: #000;
	text-align: center;
	background-color: @gray;
	border: 1px solid @gray--darken;
	// border-radius: 4px;
}







.tooltip.in {
	filter: alpha(opacity=90);
	opacity: .9;
}
.fade.in {
	opacity: 1;
}
.fade {
	opacity: 0;
	-webkit-transition: opacity .15s linear;
	-o-transition: opacity .15s linear;
	transition: opacity .15s linear;
}
