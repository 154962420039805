/**
* Таблица стилей содержит стили типографики для визуального редактора CKEditor
* переменные редактируются из файла ./src/less/variables.less
*/


/**
* Основные настройки контентной области
*/
.content {
	// height: 100%; /* учитывает последний margin-bottom у строчного элемента */
	color: @text-color;
}

/**
* Основной текст
*/
.content p,
.p {
	margin-top: @paragraph-margin-top;
	margin-bottom: @paragraph-margin-bottom;

	&.lead {
		font-size: @paragraph-lead-font-size;
	}
}

/**
* Заголовки
* -----------------------------------------------------------------------------
*/
.h1,
h1,
.content h1,
.content .h1 {
	font-family: @title-font;
	color: @heading-color;
	font-size: @heading-h1-font-size;
	line-height: 1.1;
	font-weight: @heading-font-weight;
	margin-top: @heading-margin-top;
	margin-bottom: @heading-margin-bottom;

	& small {
		font-size: 65%;
		font-weight: 400;
		line-height: 1;
		color: @text-color;
	}
}

.h2,
h2,
.content h2,
.content .h2 {
	font-family: @title-font;
	color: @heading-color;
	font-size: @heading-h2-font-size;
	line-height: 1.1;
	font-weight: @heading-font-weight;
	margin-top: @heading-margin-top;
	margin-bottom: @heading-margin-bottom;

	& small {
		font-size: 65%;
		font-weight: 400;
		line-height: 1;
		color: @text-color;
	}
}

.h3,
h3,
.content h3,
.content .h3 {
	font-family: @title-font;
	color: @heading-color;
	font-size: @heading-h3-font-size;
	line-height: 1.1;
	font-weight: @heading-font-weight;
	margin-top: @heading-margin-top;
	margin-bottom: @heading-margin-bottom;

	& small {
		font-size: 65%;
		font-weight: 400;
		line-height: 1;
		color: @text-color;
	}
}

.h4,
h4,
.content h4,
.content .h4 {
	font-family: @title-font;
	color: @heading-color;
	font-size: @heading-h4-font-size;
	line-height: 1.1;
	font-weight: @heading-font-weight;
	margin-top: @heading-margin-top;
	margin-bottom: @heading-margin-bottom;

	& small {
		font-size: 65%;
		font-weight: 400;
		line-height: 1;
		color: @text-color;
	}
}

.h5,
h5,
.content h5,
.content .h5 {
	font-family: @title-font;
	color: @heading-color;
	font-size: @heading-h5-font-size;
	line-height: 1.1;
	font-weight: @heading-font-weight;
	margin-top: @heading-margin-top;
	margin-bottom: @heading-margin-bottom;

	& small {
		font-size: 65%;
		font-weight: 400;
		line-height: 1;
		color: @text-color;
	}
}

.h6,
h6,
.content h6,
.content .h6 {
	font-family: @title-font;
	color: @heading-color;
	font-size: @heading-h6-font-size;
	line-height: 1.1;
	font-weight: @heading-font-weight;
	margin-top: @heading-margin-top;
	margin-bottom: @heading-margin-bottom;

	& small {
		font-size: 65%;
		font-weight: 400;
		line-height: 1;
		color: @text-color;
	}
}


.h2-tag,
h2.h2-tag,
.content h2.h2-tag,
.content .h2-tag {
	font-family: @title-font;
	color: #fff;
	font-size: @heading-h2-font-size;
	line-height: 1.1;
	font-weight: @heading-font-weight;
	margin-top: @heading-margin-top;
	margin-bottom: @heading-margin-bottom;
	background: @primary-color-2;
	display: inline-block;
	padding: 4px 60px 6px 25px;

	& small {
		font-size: 65%;
		font-weight: 400;
		line-height: 1;
		color: @text-color;
	}
}

/**
* Оформление текста внутри абзаца
* -----------------------------------------------------------------------------
*/
b, strong {
	font-weight: bolder;
}
i, cite, em, var, dfn {
	font-style: italic;
}
.underline {
	text-decoration: underline;
}
mark,
.mark {
	padding: .2rem;
	color: @text-color;
	background-color: #f4f4f4;
}

/**
* листинги кода
* -----------------------------------------------------------------------------
*/
code,
pre {
	font-size: 0.9rem;
	padding: 3px 8px 1px;
	background-color: #fff;
	border: 1px solid #E1E1E1;
	border-radius: 3px;
	color: #c7254e;
}
code, kbd, pre, samp {
	font-family: @mono-font;
}

hr {
	background: #ccc none repeat scroll 0 0;
    border: 0 none;
    height: 1px;
    margin-bottom: 40px;
    margin-top: 40px;
}

/**
* ссылки
* -----------------------------------------------------------------------------
*/
.a,
.content a {
	color: @link-color;
	font-size: @link-font-size;
	font-weight: @link-font-weight;
	text-decoration: @link-text-decoration;

	transition: 0.1s color ease;

	&:active {
		color: @link-active;
	}

	&:visited {
		//temp 
		//color: @link-visited;
	}

	&:hover,
	&.hover {
		color: @link-hover;
		text-decoration: none;
	}
}

.a-news-return,
.content a.a-news-return {
	color: #6e6b6b;
	font-size: @link-font-size;
	font-weight: @link-font-weight;
	text-decoration: @link-text-decoration;

	transition: 0.1s color ease;

	&:active {
		color: #6e6b6b;
	}

	&:visited {
		//temp 
		//color: @link-visited;
	}

	&:hover,
	&.hover {
		color: #6e6b6b;
		text-decoration: none;
	}
}

/**
* списки
* -----------------------------------------------------------------------------
*/

.content {

	& ul {
		margin-top: 0;
		margin-right: 0;
		margin-left: @unordered-list-margin-left;
		margin-bottom: @list-margin-bottom;
		padding-top: 0;
		padding-right: 0;
		padding-bottom: 0;
		padding-left: @unordered-list-padding-left;

		list-style-type: none;

		& li {
			position: relative;
			margin-bottom: 20px;
			line-height: @unordered-list-line-height;
			padding-left: @unordered-list-padding-left; /* место под маркер списка */
		}

		& ul {
			margin: 0;
			margin-top: 10px;
			margin-bottom: 10px;
			padding-top: 0;
			padding-left: 0;

			& li {
				margin-bottom: 0;

				&::before {
					//background-color: transparent;
				}
			}
		}

		& ol {
			margin: 0;
			margin-top: 10px;
			margin-bottom: 10px;
			padding-top: 0;
			padding-left: 0;

			& li {
				margin-bottom: 0;

				&::before {
					//background-color: transparent;
				}
			}
		}

		& > li::before {
			content: '';
			position: absolute;
			top: @unordered-list-item-marker-top;
			left: 0;
			width: @unordered-list-item-marker-width;
			height: @unordered-list-item-marker-height;
			background-color: @unordered-list-marker-color;
			border: 1px solid @unordered-list-marker-color;
			border-radius: @unordered-list-item-border-radius;
		}
	}


	/* нумерованный */
	& ol {
		margin-top: 0;
		margin-right: 0;
		margin-bottom: @list-margin-bottom;
		margin-left: 0;
		padding-top: 0;
		padding-right: 0;
		padding-bottom: 0;
		padding-left: @ordered-list-padding-left;

		list-style-type: none;
		counter-reset: num;     /* Задаём имя счетчика */

		& li {
			position: relative;
			padding-left: @ordered-list-item-padding-left;
			margin-bottom: 10px;
		}

		& ol {
			margin: 0;
			margin-top: 10px;
			margin-bottom: 10px;
			padding-left: 10px;
			padding-top: 0;
			list-style-type: none;
			counter-reset: num;     /* Задаём имя счетчика */

			& li {
				margin-bottom: 0;
				line-height: @ordered-item-line-height;
			}
			/**
			* многоуровневый нумерованный пункт списка
			*/
			li::before {
				content: counter(num) '.';  /* Выводим число */
				counter-increment: num;     /* Увеличиваем значение счётчика */
				display: inline-block;
				vertical-align: baseline;
				margin-right: 2px;
				color: @ordered-list-item-marker-color;
				font-size: @ordered-item-marker-font-size;
				font-weight: @ordered-item-markger-font-weight;
				line-height: @ordered-item-line-height;
			}
		}

		& ul {
			margin: 0;
			margin-top: 10px;
			margin-bottom: 10px;
			padding-left: 10px;
			padding-top: 0;
			list-style-type: none;
			counter-reset: num;     /* Задаём имя счетчика */

			& li {
				margin-bottom: 0;
				line-height: @ordered-item-line-height;
			}
			
		}

		& > li::before {
			content: counter(num) '.';  /* Выводим число */
			counter-increment: num;     /* Увеличиваем значение счётчика */
			position: absolute;
			top: 0;
			left: 0;
			margin-right: 5px;
			width: 22px;
			text-align: right;
			color: @ordered-list-item-marker-color;
			font-size: @ordered-item-marker-font-size;
			font-weight: @ordered-item-markger-font-weight;
			line-height: @ordered-item-line-height;
		}
	}

	ul li p,
	ol li p {
		margin-bottom: 0;
		margin-top: 0;
	}


}

/**
* таблица
* -----------------------------------------------------------------------------
*/
.content {

	& table {
		width: 100%;

		&.table-striped {
			& tr:nth-child(even) {
				background-color: @table-striped-background-color;
			}
		}

		&.table-hover {
			& tr:hover {
				background-color: @table-tr-hover-background-color;
			}
		}

	}
}

.table {
	table-layout: fixed;
	border-spacing: @table-border-spacing;
	margin-bottom: @table-margin-bottom;
	border-collapse: collapse;


	& th {
		padding-top: @table-cell-padding-top;
		padding-bottom: @table-cell-padding-bottom;
		padding-left: @table-cell-padding-left;
		padding-right: @table-cell-padding-right;
		background: @primary-color-2;
		font-weight: @table-th-font-weight;

		font-size: 14px;

		color: @table-th-color;
		text-align: @table-th-text-align;
		vertical-align: @table-th-vertical-align;
		border: 1px solid #fff;
	}

	& td {
		padding-top: @table-cell-padding-top;
		padding-bottom: @table-cell-padding-bottom;
		padding-left: @table-cell-padding-left;
		padding-right: @table-cell-padding-right;

		font-size: 14px;
		color: #1c2036;

		font-weight: @table-td-font-weight;
		text-align: @table-td-text-align;
		vertical-align: @table-td-vertical-align;
		border: 1px solid @primary-color-2;
	}

}

/**
* цитата blockquote
* -----------------------------------------------------------------------------
*/
.content {

	& blockquote {
		font-size: @blockquote-font-size;
		border-color: @blockquote-border-color;
		border-style: solid;
		border-width: 1px 1px 1px 1px;

		padding: 12px 2%;
		margin: 0 2% 20px;

		& footer,
		& small {
			font-size: @blockquote-font-size * 0.8;

			&::before {
				content: '\2014 \00A0';
			}
		}
		.h4,
		h4,
		.content h4,
		.content .h4 {
			color: #6e6b6b;
			font-family: @main-font;
		}
	}
}

/**
* элемент важно и комментарий
* -----------------------------------------------------------------------------
*/
.important,
.content .important {
	background-color: @important-background-color;
	color: #fff;
	padding: 32px 4.5%;
	margin-bottom: 40px;

	& h3 {
		color: #fff;
	}

	& p {
		margin-bottom: 0;
	}
}

.comment,
.content .comment {
	background-color: @comment-background-color;
	color: #fff;
	padding: 32px 8.5%;
	margin-bottom: 40px;

	& h3,
	& .h3 {
		color: #fff;
	}

	& p {
		margin-bottom: 0;
		font-style: italic;
	}
}

/**
* сноски (footnotes)
* -----------------------------------------------------------------------------
*/
.content {

	.footnote {
		color: @text-color;
		text-decoration: none;

		&:hover {
			color: @link-hover;
		}
		&:visited {
			color: @text-color;
		}

		& sup {
			color: @link-hover;
		}
	}

	.footnotes {
		& li {
			font-size: 14px;

			&::before {
				font-size: 14px;
			}
		}
	}

}
.content img {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}
.img-left {
	float: left;
	margin: 0 20px 20px 0;
}

@media (max-width: 1024px) {
	code,
	pre {
		display: block;
		overflow-x: scroll;
		padding: 10px;
	}
	.content table.table {
		width: auto;
		max-width: 100%;
	}
	.table-responsive {
		position: relative; /* тень выравнивается относительно этого блока */
		overflow: hidden; /* нужно для того чтоб тень не прокручивалась */
		margin-left: auto;
		margin-right: auto;
		max-width: 100%;
	}
	.table-responsive div {
		overflow-x: auto; /* этот див нужен для тени */
	}
	.table-responsive div::after { /* тень остается на одном месте благодаря вложенному диву */
		display: block;
		content: '';
		position: absolute;
		top: 0;
		left: 100%;
		width: 30px;
		height: 100%;
		border-radius: ~"10px 0 0 10px / 50% 0 0 50%"; /* тильда и ковычки это для less-файлов */
		box-shadow: -5px 0 10px rgba(0, 0, 0, 0.25);
	}
	.content ul,
	.content ol {
		margin-left: 1%;
		padding-left: 1%;
	}
	.content ul ul,
	.content ol ol {
		padding-left: 0;
	}
	.content ul li {
		padding-left: 25px;
	}
}
